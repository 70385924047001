import axios from "axios"
import PropTypes from "prop-types"
import React, { useContext, useEffect, useState } from "react"
import { Col, Container, Row } from "react-bootstrap"
import styled from "styled-components"
import { Box, Span, Text } from "../../components/Core"
import GlobalContext from "../../context/GlobalContext"
import { device } from "../../utils"
import PricingTable from "./PricingTable"
import { getReferralCodePricingTitle } from "./tableTypes/utils"

const StyledBox = styled(Box)`
  width: 100%;
  text-align: center;
`
export const HighlightedText = styled(Span)`
  font-weight: 600;
  color: ${({ theme }) => theme.colors.green};
`

const RightDecorator = styled.div`
  position: absolute;
  width: 8.75rem;
  height: 8.75rem;
  right: 0;
  transform: translate(2rem, 30rem);
  border: 1px solid;
  border-color: ${({ theme }) => theme.colors.green};
  border-radius: 50%;

  @media ${device.xs} {
    display: none;
  }
  @media ${device.lg} {
    display: block;
  }
`

const PricingSection = ({ title, subtitle, isPage, pt, pb }) => {
  const { referralCode: code } = useContext(GlobalContext)
  const [referralData, setReferralData] = useState(null)

  useEffect(() => {
    const getReferralCodeInfo = async () => {
      try {
        await axios
          .get(
            process.env.GATSBY_API_URL + "/referral-codes/check-referral-code",
            {
              withCredentials: false,
              params: { code: code },
            }
          )
          .then(res => res.data)
          .then(data => {
            setReferralData(data)
          })
      } catch (e) {
        // do nothing
      }
    }
    if (code) getReferralCodeInfo()
  }, [code])

  return (
    <React.Fragment>
      <Container
        css={`
          position: relative;
          padding-top: ${pt};
          padding-bottom: ${pb};
        `}
      >
        <RightDecorator />
        <Row>
          <Col>
            <StyledBox>
              {isPage && referralData && referralData.code.associated_logo && (
                <Col>
                  <img
                    src={referralData.code.associated_logo}
                    alt="referral logo"
                    css={`
                      max-width: 13rem;
                    `}
                  />
                </Col>
              )}
              <Text
                mb={[2, 1, 2, 3]}
                fontSize={["1.5rem", "1.8rem", "2.0rem", "2.2rem", "2.5rem"]}
                color="primary"
                className={"text-center"}
                opacity={0.9}
                fontWeight="bold"
                lineHeight={["35px", "50px"]}
                css={`
                  max-width: ${isPage ? "50%" : "100%"};
                  margin: 1rem auto;
                  @media (max-width: 576px) {
                    max-width: 100%;
                  } ;
                `}
              >
                {isPage && referralData
                  ? getReferralCodePricingTitle(referralData)
                  : title}
                <HighlightedText>. </HighlightedText>
              </Text>
              <Text
                mb={[3, 2, 3, 4]}
                fontSize={["1rem", "1rem", "1rem", "1.18rem", "1.25rem"]}
                color="primary"
                className={"text-center p-0 pb-3"}
                opacity={0.9}
                fontWeight={"400"}
                lineHeight={["20px", "24px"]}
                css="max-width: 70%; text-align:center !important; display: inline-block;"
              >
                {isPage && referralData
                  ? `El descuento se hará efectivo en la etapa de pago.`
                  : subtitle}
              </Text>
            </StyledBox>
          </Col>
        </Row>
      </Container>
      <PricingTable />
    </React.Fragment>
  )
}

PricingSection.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  isPage: PropTypes.bool,
  pt: PropTypes.string,
}

PricingSection.defaultProps = {
  title: "Precios diseñados para cada independiente",
  isPage: false,
  pt: "9rem",
}

export default PricingSection
