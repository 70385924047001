import React from "react"
import { Box } from "../../components/Core"
import AllInOne from "./tableTypes/AllInOne"

const PricingTable = () => {
  return (
    <Box style={{ background: "#FFFFFF" }}>
      <AllInOne />
    </Box>
  )
}

export default PricingTable
