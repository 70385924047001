import PropTypes from "prop-types"
import React from "react"

const ArrowIcon = ({ color }) => {
  return (
    <React.Fragment>
      <span
        css={`
          align-self: end;
        `}
      >
        <svg
          width="12"
          height="12"
          viewBox="0 0 12 12"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M9.129 5.22859L5.106 1.09077L6.1665 -2.69546e-07L12 6L6.1665 12L5.106 10.9092L9.129 6.77141L-2.28549e-07 6.77141L-2.95988e-07 5.22859L9.129 5.22859Z"
            fill={color}
          />
        </svg>
      </span>
    </React.Fragment>
  )
}

ArrowIcon.propTypes = {
  color: PropTypes.string,
}

export default ArrowIcon
