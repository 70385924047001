import React from "react"
import { Text } from "../../../components/Core"
import { default as checkIcon } from "../../../images/svg/icons/checkIcon.svg"
import { default as closeIcon } from "../../../images/svg/icons/closeIcon.svg"

export const textHandler = (text, font = "400", opacity = 0.9) => (
  <Text
    fontSize={["1rem", "1rem", "1rem", "1.18rem", "1.25rem"]}
    color="#002432"
    className={"p-0"}
    opacity={opacity}
    fontWeight={font}
    lineHeight={["20px", "24px"]}
    letterSpacing={"0.01em"}
  >
    {text}
  </Text>
)

export const iconHandler = iconType => {
  let icon
  if (iconType === "include")
    icon = <img src={checkIcon} alt="checkIcon" height="100%" />
  if (iconType === "exclude")
    icon = <img src={closeIcon} alt="closeIcon" height="100%" />
  return icon
}

export const licensePathHandler = (licenseId, code, userEmail) => {
  const baseUrl = `${process.env.GATSBY_AIO_APP_URL}/license/${licenseId}`;
  if (code) return `${baseUrl}?referralCode=${code}`;
  if (userEmail) return `${baseUrl}/renew/pay?payload=${userEmail}`;
  return baseUrl
}

const getReferralCodePricingNames = (referralData) => {
  if(referralData.code.associated_user){
    return `${referralData.code.associated_user.first_name} ${referralData.code.associated_user.last_name}`
  }
  if(referralData.code.associated_name){
    return referralData.code.associated_name
  }
}


export const getReferralCodePricingTitle = (referralData) => {
  if(referralData.code.associated_user || referralData.code.associated_name){
    return `${getReferralCodePricingNames(referralData)} te ha dado un ${referralData.code.discount_percent}% 
                    de descuento en cualquier plan que escojas`
                  }
  return `Has recibido un un ${referralData.code.discount_percent}% de descuento en cualquier plan que escojas`
}