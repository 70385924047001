import { Col, Row } from "react-bootstrap"
import styled from "styled-components"
import { Box, Span, Text } from "../../../components/Core"

export const StyledRow = styled(Row)`
  margin: 0;
  padding: 0;
  ${({ pt }) => pt && `padding-top: ${pt};`}
  ${({ pb }) => pb && `padding-bottom: ${pb};`}
`

export const StyleCol = styled(Col)`
  border-bottom: 1px solid ${({ theme }) => theme.colors.linebreak};
  box-sizing: border-box;
  min-width: 10rem;
  ${({ max_width }) => max_width && `max-width: 20rem !important`};
  ${({ pb }) => pb && `padding-bottom: ${pb};`}
  ${({ pt }) => pt && `padding-top: ${pt};`}
  ${({ pl }) => pl && `padding-left: ${pl};`}
  ${({ pr }) => pr && `padding-right: ${pr};`}
  
  ${({ theme, active, isheader }) =>
    active &&
    isheader &&
    `
    border-top: 1px solid ${theme.colors.textHover};
    border-left: 1px solid ${theme.colors.textHover};
    border-right: 1px solid ${theme.colors.textHover};
    border-radius: 8px 8px 0 0;
  `}
  ${({ theme, active, is_sub_header }) =>
    active &&
    is_sub_header &&
    `
    border-left: 1px solid ${theme.colors.textHover};
    border-right: 1px solid ${theme.colors.textHover};
  `}
  // ${({ theme }) => theme.colors.light}
  ${({ theme, border, active, first }) =>
    border &&
    !first &&
    `
    border-left: 1px solid ${
      active ? theme.colors.textHover : theme.colors.linebreak
    };
  `}
  ${({ theme, border, active, last }) =>
    border &&
    last &&
    `
    border-right: 1px solid ${
      active ? theme.colors.textHover : theme.colors.linebreak
    };
  `}
  ${({ theme, border, active, lastrow }) =>
    border &&
    lastrow &&
    `
    border-bottom: 1px solid ${
      active ? theme.colors.textHover : theme.colors.linebreak
    };
    ${active && lastrow && `border-radius: 0 0 8px 8px;`}
  `}
  ${({ rb }) => rb && `border-bottom: none;`}
  ${({ theme, headerBorder }) =>
    headerBorder &&
    ` border-top: 1px solid ${theme.colors.linebreak};
      border-left: 1px solid ${theme.colors.linebreak};
      border-right: 1px solid ${theme.colors.linebreak};
      border-radius: 8px 8px 0 0;
  `}
`
export const DiscountSpan = styled(Span)`
  border: 1px solid #0dbc7d;
  box-sizing: border-box;
  border-radius: 4px;
  color: #0dbc7d;
  padding: 4px;
  font-weight: 700;
  font-size: 0.75rem;
  margin-left: 0.5rem;
`
export const AllinOneSectionBox = styled(Box)`
  display: flex;
  flex-direction: column;
  min-height: 2rem;
  justify-content: start;
`

export const AllInOneSectionText = styled(Text)`
  color: ${({ theme, active }) =>
    active ? theme.colors.textHover : theme.colors.primary};
  font-weight: 400;
  line-height: 20px;
  text-align: center;
  font-size: 1.2rem;
`

export const FeesSpan = styled(Span)`
  color: #0dbc7d;
  font-weight: 700;
  font-size: 0.75rem;
  line-height: 15px;
  letter-spacing: 0.02em;
  ${({ text }) => !text && `min-height: 1rem`};
`

export const HyperLink = styled.a`
  text-decoration: none;
  cursor: grab;
`

export const DotText = styled(Span)`
  font-weight: 600;
  color: ${({ theme }) => theme.colors.textHover};
  text-align: center;
  margin-top: -18px;
  font-size: 1.5rem;
`
export const SelectPlanBtn = styled.button`
  margin: 0.75rem 0;
  width: 80%;
  min-height: 3rem;
  background: ${({ theme, active }) =>
    active ? theme.colors.primary : "#ffffff"};
  color: ${({ theme, active }) => (active ? "#FFFFFF" : theme.colors.primary)};
  letter-spacing: 0.02em;
  font-size: 0.875rem;
  padding-left: 1rem;
  padding-right: 1rem;

  border: 1px solid #dfe1e9;
  box-sizing: border-box;
  border-radius: 40px;

  &:hover {
    background: ${({ theme }) => theme.colors.textHover};
    border-radius: 40px;
    text-align: center;
    color: #ffffff;
    transition-timing-function: ease-in-out;
    -webkit-transition-timing-function: ease-in-out;
  }

  @media (max-width: 1400px) {
    width: 100%;
  }

  @media (max-width: 992px) {
    width: 100%;
  }
`
export const TrialPlanButton = styled.button`
  width: 80%;
  background: ${({ theme, active }) =>
    active ? theme.colors.primary : "#ffffff"};
  color: ${({ theme, active }) => (active ? "#FFFFFF" : theme.colors.primary)};
  letter-spacing: 0.02em;
  font-size: 0.875rem;
  font-weight: 700;
  padding-left: 1rem;
  padding-right: 1rem;
  border: 0px solid;
  border-radius: 40px;

  &:hover {
    background: ${({ theme }) => theme.colors.textHover};
    color: #ffffff;
    transition-timing-function: ease-in-out;
    -webkit-transition-timing-function: ease-in-out;
  }

  @media (max-width: 1400px) {
    width: 100%;
  }

  @media (max-width: 992px) {
    width: 100%;
  }
`

export const DescriptionSpan = styled(Span)`
  font-weight: 500;
  font-size: 1rem;
  line-height: 15px;
  letter-spacing: 0.01em;
  color: ${({ theme }) => theme.colors.primary};
  opacity: 0.8;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
`
export const DescriptionSpanWithoutMargin = styled(DescriptionSpan)`
  margin-top: 0px;
`
export const ColumTextStyle = styled(Text)`
  font-weight: 400;
  font-size: 0.95rem;
  line-height: 20px;
  color: ${({ theme }) => theme.colors.primary};
`

export const Decorator = styled.div`
  display: flex;
  width: 6.313rem;
  height: 1.938rem;
  background: ${({ theme }) => theme.colors.textHover};
  position: absolute;
  transform: translate(0rem, -2rem);
  border-radius: 0;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  text-align: center;
  justify-content: center;
  align-items: center;
  font-weight: 700;
  font-size: 12px;
  line-height: 15px;
  letter-spacing: 0.02em;
  color: #ffffff;
  text-decoration: uppercase;

  @media (max-width: 1400px) {
    transform: translate(4.2rem, -2rem);
  }
  @media (max-width: 1200px) {
    transform: translate(8.3rem, -2rem);
  }
  @media (max-width: 990px) {
    transform: translate(14rem, -2rem);
  }
  @media (max-width: 768px) {
    width: 5.313rem;
    height: 1.938rem;
    transform: translate(5rem, -2rem);
  }
  @media (max-width: 520px) {
    transform: translate(7rem, -2rem);
  }
`
export const PrincipalRowStyle = styled(Row)`
  @media (max-width: 992px) {
    display: none;
  }
`
export const ResponsiveDivStyle = styled(Row)`
  display: flex;
  justify-content: center;
`
